<template>
  <div class="reportMarket"
    v-loading="loading">
    <template v-if="showType == 'list'">
      <d-search :searchData="searchData"
                @searchEvent="searchEvent"
                @resetEvent="resetEvent" />
      <div class="operation">
        <el-button size="small"
          type="primary"
          @click="addItem">新增</el-button>
        </div>
      <d-lable :tableData='tableData'
        :columnData='columnData'>

        <template v-slot:handle="row">
          <el-link type="primary" @click="goDetail(row.item)">详情</el-link>
        </template>
      </d-lable>
      <d-paging :pager="pager"
                @change="getItemList" />
      <el-dialog title="新增公告"
        :visible.sync="noticeDialogVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        top="5vh"
        width="50%"
        @close="dialogClose">
          <div class="oticeDialogContent">
            <div class="flex-row">
              <div class="title"><span style="color:red">*</span>发送类型：</div>
              <el-select v-model="sendType" placeholder="请选择">
                <el-option label="店铺" :value="1"></el-option>
                <el-option label="楼层" :value="3"></el-option>
                <el-option label="业态" :value="2"></el-option>
              </el-select>
            </div>
            <div class="flex-row">
              <div class="title"><span style="color:red">*</span>选择店铺：</div>
              <!-- <el-cascader
                v-if="sendType==0"
                v-model="shopObj.value"
                clearable
                filterable
                :options="shopObj.options"
                :props="{
                  multiple: true
                }"
                :collapse-tags="true"
                placeholder="请选择"
                @visible-change="visibleChange"
                @change="selectHandle">
              </el-cascader> -->
              <cascader v-if="sendType==1" :dataObj="shopObj" @changeHandle="changeHandleFn"></cascader>
              <cascader1 v-if="sendType==3" :dataObj="floorObj" @changeHandle="changeHandleFnFloor"></cascader1>
              <cascader2 v-if="sendType==2" :dataObj="formatObj"  @changeHandle="changeHandleFnFormat"></cascader2>
              <!-- <el-select v-model="floorObj.value"
                v-if="sendType==1"
                placeholder="请选择"
                multiple
                collapse-tags
                filterable
                @change="floorSelectChange">
                  <el-checkbox v-model="floorObj.selectChecked"
                                @change="floorSelectAll"
                                style="padding: 0 20px">全选</el-checkbox>
                  <el-option v-for="item in floorObj.options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                              :disabled="item.disabled">
                  </el-option>
              </el-select>

              <el-select v-model="formatObj.value"
                v-if="sendType==2"
                placeholder="请选择"
                multiple
                collapse-tags
                filterable
                @change="formatSelectChange">
                  <el-checkbox v-model="formatObj.selectChecked"
                                @change="formatSelectAll"
                                style="padding: 0 20px">全选</el-checkbox>
                  <el-option v-for="item in formatObj.options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                              :disabled="item.disabled">
                  </el-option>
              </el-select> -->
            </div>
            <div class="flex-row" style="margin-left: 120px; width: 350px;">
              <div class="conten">
                <div v-for="(item) in contenList" :key="item">
                  <div class="conten-item">{{item}}</div>
                </div>
              </div>
            </div>
            <div class="flex-row">
              <div class="title"><span style="color:red">*</span>标题：</div>
              <!-- style="padding-right: 47px;" show-word-limit -->
              <el-input v-model="title" maxlength="50"  placeholder="请输入标题"></el-input>
            </div>
            <div class="flex-row">
              <div class="title"><span style="color:red">*</span>公告类型：</div>
              <el-radio-group v-model="noticeType">
                <el-radio :label="0">内容编辑</el-radio>
                <el-radio :label="1">跳转链接</el-radio>
              </el-radio-group>
            </div>
            <div class="flex-row" v-if="noticeType==0">
              <div class="title">内容：</div>
            </div>
            <div class="flex-row" v-if="noticeType==0" style="margin-left: 120px; width: 550px;">
                <quill-editor 
                  v-model="content" 
                  ref="myQuillEditor" 
                  :options="editorOption" 
                  @blur="onEditorBlur($event)" 
                  @focus="onEditorFocus($event)"
                  @change="onEditorChange($event)">
              </quill-editor>
            </div>
            <div class="flex-row" v-if="noticeType==0" style="margin-bottom:4px">
              <!-- ,.mp4,.ogg,.flv,.avi,.wmv,.rmvb -->
              <div class="title">上传图片：</div>
              <div>
                <div style="color:#AAAAAA;font-size:14px;margin-left: 10px;">
                  最大图片不得超过10MB，共20个文件
                  </div>
                <div style="display:flex;">
              <!-- <el-upload
                ref="upload"
                class="upload-demo"
                action="/business/upload"
                accept=".png,.jpg,.jpeg"
                :show-file-list="false"
                :on-change="changeFile"
                :http-request="uploadFile"
                :before-upload="beforeUpload"
                :limit="limitFileNum"
                :on-exceed="overFile"
              :multiple="true">
            <i class="el-icon-plus"></i>
              </el-upload> -->
            <viewer :images="images" style="display: flex;flex-wrap: wrap;">
              <el-upload
                :style="{pointerEvents:images.length>=20?'none':''}"
                ref="upload"
                class="upload-demo"
                action="/business/upload"
                accept=".png,.jpg,.jpeg"
                :show-file-list="false"
                :on-change="changeFile"
                :http-request="uploadFile"
                :before-upload="beforeUpload"
                :limit="limitFileNum"
                :on-exceed="overFile"
              :multiple="true">
            <i class="el-icon-plus"></i>
              </el-upload>
              <div class="images-item" v-for="(item,index) in images.filter(temp=>{
                return ['.png','.jpg','.jpeg','.PNG','.JPG','.JPEG'].includes(temp.url.slice(temp.url.lastIndexOf('.')))
                })" :key="index">
                <img :src="item.url" :alt="item.name" @click="showImg(item)">
                <div @click="removeImg(index)" class="delete">-</div>
              </div>
            </viewer>
            <div class="images-item" v-for="(item,index) in images.filter(temp=>{
                return ['.mp4','.wmv','.m4v','.avi','.ogg','.flv','.rmvb'].includes(temp.url.slice(temp.url.lastIndexOf('.')))
                })" :key="index">
                <!-- <img :src="item.url" alt="视频" @click="showViedo(item)"> -->
                <div  @click="showViedo(item)" style="width: 100%;height: 100%;margin-left: 2px;margin-top: 1px;background: #bbb8b8;display: flex;align-items: center;justify-content: center;">
                  <img style="margin:0;width: 40px;height: 40px;" src="https://reconova-test.oss-cn-zhangjiakou.aliyuncs.com/AIOS/miniprogram/pic/video.png">
                  <!-- <div style="width: 0;height: 0;border: solid 18px #fff;border-radius: 8px;border-top-color: transparent;border-right-color: transparent;border-bottom-color: transparent;transform: translate(29px, 16px);"></div> -->
                </div>
                <div @click="removeImg(index)" class="delete">-</div>
              </div>
            <div  class="wordfile" v-if="viedoUrl" @click="closeViedo">
              <div @click.stop="closeViedo" style="position:absolute;top: 6%;right: 7%;font-size: 40px;cursor: pointer;"><i class="el-icon-error"></i></div>
              <div style="width:80%;height:80%;margin-left: 10%;margin-top: 6%;background: #fff;">
                <video width="100%" height="100%" controls autoplay>
                  <source :src="viedoUrl" type="video/ogg">
                  <source :src="viedoUrl" type="video/mp4">
                  <source :src="viedoUrl" type="video/webm">
                  <object data="movie.mp4" width="100%" height="100%">
                    <embed width="100%" height="100%" :src="viedoUrl">
                  </object>
                </video>
              </div>
            </div>
              </div>
            </div>
            <!-- <el-dialog  :visible.sync="imgDialogVisible" :title="imgName" append-to-body width="90%" custom-class="imgDialog">
              <img width="100%" style="max-height:750px" :src="imageUrl" alt="">
            </el-dialog> -->
          </div>
          
            <div class="flex-row" v-if="noticeType==0" style="margin-bottom:4px">
              <div class="title">上传附件：</div>
              <div>
                <div style="color:#AAAAAA;font-size:14px;margin-left: 10px;">请上传pdf,zip,rar,word,excel,ppt文件，单个文件最大100MB，最多上传10个文件</div>
              <div style="display: flex;flex-wrap: wrap;align-items: center;">
              <el-upload
              :style="{pointerEvents:files.length>=10?'none':''}"
                ref="upload1"
                class="upload-demo"
                action="/business/upload"
                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.dwg,.zip,.rar"
                :show-file-list="false"
                :on-change="changeFile"
                :http-request="uploadFile1"
                :before-upload="beforeUpload1"
                :limit="limitFileNum1"
                :on-exceed="overFile1"
              :multiple="true">
            <i class="el-icon-plus"></i>
              </el-upload>
          <div class="files-item" v-for="(item,index) in files" :key="index">
            <div class="file-img">
              <img :src="getDefaultImg(item.name)" alt="" @click="getFileImg(item)">
              <div :title="item.name">{{item.name}}</div>
            </div>
            <div @click="removeFile(index)" class="delete">-</div>
            <!-- <attachments class="enclosure" :fileList.sync="files" :canDelete="true"></attachments> -->
          </div>
          <div  class="wordfile" v-if="fileUrl" @click="closeIframe">
            <div @click.stop="closeIframe" style="position:absolute;top: 2%;right: 7%;font-size: 40px;cursor: pointer;"><i class="el-icon-error"></i></div>
            <iframe :src="fileUrl" width='80%' height='90%' frameborder='0'/>
          </div>
          </div>
          </div>
          <!-- <el-dialog  :visible.sync="fileDialogVisible" :title="fileName" append-to-body width="90%" custom-class="imgDialog">
            <iframe style="width: 100%;height: 100%;" :src="fileUrl" frameborder="0" ></iframe>
          </el-dialog> -->
            </div>
            <div class="flex-row" v-if="noticeType==1">
              <div class="title"><span style="color:red">*</span>跳转链接：</div>
              <el-input v-model="noticeLink" maxlength="9999" placeholder="请输入跳转链接"></el-input>
            </div> 
            <div class="btn">
              <el-button @click="back">取消</el-button>
              <el-button type="primary" @click="sure">保存</el-button>
            </div>
          </div>
      </el-dialog>
    </template>
    <template v-if="showType == 'detail'">
      <detail :id="listId" @changeType="changeType"></detail>
    </template>
  </div>
</template>

<script>

import DSearch from '@/components/d-search'
import DLable from '@/components/d-table'
import DPaging from '@/components/d-paging'
import {getShopCode} from "@/utils/utils";
import uploadCommon from "@/utils/upload";
import Enclosure from "@/pages/businessModule/examine/formDetail/attachments";
  import {
    getFloorShop,
    getModel,
    processUpload,
  } from "@/services/check";
  import {
    uploadFile
  } from "@/services/sysapi";
  import {Loading} from "element-ui";
  import Attachments from "@/pages/businessModule/examine/formDetail/attachments";
  import {isIE, validateApprover} from "@/utils/utils";
  import Flow from "@/components/flow";
  import {flattenList} from "@/utils/utils";
// import Detail from "@/pages/businessModule/notice/detail";
import Detail from "./detail";
import Cascader from "./cascader";
import Cascader1 from "./cascader1";
import Cascader2 from "./cascader2";
// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  data () {
    return {
      viedoUrl:'',
      preSelected: [], // 上次选中的数据
      originData: [], //  源数据平铺成一级节点
      shopObj: {
        value: [],
        options: []
      }, 
      floorObj:{
        value: [],
        options: [],
        selectChecked: false,
      },
      formatObj:{
        value: [],
        options: [],
        selectChecked: false,
      },
      contenList: [],
      noticeType: 0,
      noticeLink: null,
      listId: null,
      showType: 'list',
      imgName:'',
      imgDialogVisible: false,
      imageUrl: '',
      fileUrl: '',
      fileName:'',
      fileDialogVisible: false,
      imgUrl: '',
      images: [],
      files: [],
      typeId: 1,
      loadingOption: {
          lock:true,
          text:'文件上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        },
      uploadFileNum:0,
      uploadFileNum1:0,
      shop: null,
      sendType: 1,
      title: null,
      noticeDialogVisible: false,
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      loading: false,
      searchData: {
        searchItem: [{
          label: '标题名称',
          value: 'title',
          type: 'commonInput',
          dateType: 'month',
          placeholder: '请输入标题名称'
        },  {
          label: '类型',
          value: 'type',
          type: 'commonSelect',
          clearable: false,
          placeholder: '',
          options: [
            {
              label: '全部',
              value: null,
            },
            {
              label: '店铺',
              value: 1,
            },
            {
              label: '业态',
              value: 2
            },
            {
              label: '楼层',
              value: 3
            }
          ],
        },
        {
          label: '日期',
          value: 'date',
          type: 'commonDatetime',
          placeholder: '请选择日期'
        }
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          title: null,
          type: null,
          date: ['',''],
        }
      },
      columnData: [
        // { prop: 'floorName', label: '楼层' },
        // { prop: '', label: '业态' },
        // { prop: 'shopName', label: '店铺', width: '120' },
        { prop: 'title', label: '通知标题' },
        { prop: 'type', label: '通知类型' },
        { prop: 'createTime', label: '操作时间' },
        { prop: 'handleUserName', label: '操作人' },
        {
          prop: 'operate', label: '操作', type: {}
        }
      ],
      tableData: [],
      content: '', //双向数据绑定数据
      editorOption: {
      modules:{
        toolbar: [
            ["bold", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
            // ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
            [{ 'header': 1 }, { 'header': 2 }],
            // [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
            [{ indent: "-1" }, { indent: "+1" }],
            // [{ size: ['small', false, 'large', 'huge']}], // 配置字号
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
            [{ font: []}], //显示字体选择
            [{ align: [] }], // 对齐方式-----
            ["clean"], // 清除文本格式-----
            ]
        },
        placeholder: "请输入内容..."
     }, //编辑器配置项
    contentLength: 0,
    }
  },
  computed:{
      limitFileNum(){
        return 20-this.images.length
      },
      limitFileNum1(){
        return 10-this.files.length
      }
    },
  components: {
    DSearch,
    DLable,
    DPaging,
    Flow,
    Enclosure,
    Attachments,
    Detail,
    quillEditor,
    Cascader,
    Cascader1,
    Cascader2,
  },
  created () {
    this.init()
  },
  mounted () {
    this.$nextTick(()=>{
      document.addEventListener('keyup',(e)=>{
        if(e.keyCode==27){
          this.viedoUrl&&this.closeViedo()
          this.fileUrl&&this.closeIframe()
        }
      })
    });
  },
  watch:{
    'noticeDialogVisible'(v){
        if(v) return
        this.shopObj.value = []
        this.floorObj.value = []
        this.formatObj.value = []
        this.title=null
        this.content=``
        this.contenList=[]
        this.images=[]
        this.files=[]
        this.noticeLink=null

      },
      'sendType'(v){
        if(v==0){
          this.changeHandleFn(this.shopObj.value)
        }
        if(v==1){
          this.changeHandleFnFloor(this.floorObj.value)
        }
        if(v==2){
          this.changeHandleFnFormat(this.formatObj.value)
        }
      },
      'noticeType'(v){
        if(v==0){
          // this.noticeLink = null
        }
      },
    
  },
  methods: {
    init () {
      this.getItemList()
      this.getAddOptions()
    },
    getAddOptions(){
        //
      this.$api.busapi.getBusinessList().then(res=>{
        if(res.code==200){
          // 业态
          let data = res.data
          data.forEach(item=>{
            item.label = item.name
            item.value = item.id
          })
          this.formatObj.options = data
        }
      })
      this.$api.busapi.getFloorList().then(res=>{
        if(res.code==200){
          // 楼层
          let data = res.data
          data.forEach(item=>{
            item.label = item.name
            item.value = item.id
          })
          this.floorObj.options = data
        }
      })
      this.$api.busapi.getShopList().then(res=>{
        if(res.code==200){
          // 店铺
          let  data = res.data.childList
          data.forEach(item=>{
            item.label = item.name
            item.value = item.id
            item.children = item.childList
            if(item.childList){
              item.childList.forEach(item2=>{
                item2.label = item2.name
                item2.value = item2.id
              })
            }
          })
          this.shopObj.options = data
        }
      })
    },
    changeHandleFn(val){
      let optionval = JSON.parse(JSON.stringify(val))
      let flatten = flattenList(JSON.parse(JSON.stringify(this.shopObj.options)),'children')
      let hasAll = (optionval.reduce((temp,item)=>{temp.push(...item);return temp},[])).filter(item2=>{return item2=="全选"})
      let ids = optionval.reduce((temp,item)=>{
        if(item.length==1){
          temp.push(...item)
          return temp
        }else{
          temp.push(...(item.slice(1)))
          return temp
        }
      },[])
      let getIds = []
      if(hasAll.length==0){
        this.contenList = []
        ids.forEach(id=>{
          flatten.forEach(item3=>{
            if(item3.value == id){
              this.contenList.push(`${item3.label}`)
            }
          })
        })
      }else{
        this.contenList = ['全部']
      }
      // this.shopObj.value = val
    },
    changeHandleFnFloor(val){
      let optionval = JSON.parse(JSON.stringify(val))
      let flatten = flattenList(JSON.parse(JSON.stringify(this.floorObj.options)),'children')
      let hasAll = (optionval.reduce((temp,item)=>{temp.push(...item);return temp},[])).filter(item2=>{return item2=="全选"})
      let ids = optionval.reduce((temp,item)=>{
        if(item.length==1){
          temp.push(...item)
          return temp
        }else{
          temp.push(...(item.slice(1)))
          return temp
        }
      },[])
      let getIds = []
      if(hasAll.length==0){
        this.contenList = []
        ids.forEach(id=>{
          flatten.forEach(item3=>{
            if(item3.value == id){
              this.contenList.push(`${item3.label}`)
            }
          })
        })
      }else{
        this.contenList = ['全部']
      }
      // this.floorObj.value = val
    },
    changeHandleFnFormat(val){
      let optionval = JSON.parse(JSON.stringify(val))
      let flatten = flattenList(JSON.parse(JSON.stringify(this.formatObj.options)),'children')
      let hasAll = (optionval.reduce((temp,item)=>{temp.push(...item);return temp},[])).filter(item2=>{return item2=="全选"})
      let ids = optionval.reduce((temp,item)=>{
        if(item.length==1){
          temp.push(...item)
          return temp
        }else{
          temp.push(...(item.slice(1)))
          return temp
        }
      },[])
      let getIds = []
      if(hasAll.length==0){
        this.contenList = []
        ids.forEach(id=>{
          flatten.forEach(item3=>{
            if(item3.value == id){
              this.contenList.push(`${item3.label}`)
            }
          })
        })
      }else{
        this.contenList = ['全部']
      }
      // this.formatObj.value = val
    },
    floorSelectChange(){
      if(this.floorObj.value.length == this.floorObj.options.length){
        this.floorObj.selectChecked = true
      }else{
        this.floorObj.selectChecked = false
      }
    },
    floorSelectAll(checke){
      if(checke){
        this.floorObj.value = this.floorObj.options.map(item=>{
          return item.value
        })
      }else{
        this.floorObj.value = []
      }
    },
    formatSelectChange(){
      if(this.formatObj.value.length == this.formatObj.options.length){
        this.formatObj.selectChecked = true
      }else{
        this.formatObj.selectChecked = false
      }
    },
    formatSelectAll(checke){
      if(checke){
        this.formatObj.value = this.formatObj.options.map(item=>{
          return item.value
        })
      }else{
        this.formatObj.value = []
      }
    },
    visibleChange(visible) {
      if(visible) {
        this.shopObj.options.unshift({ label: '全选', value: '全选' })
      }else {
        this.shopObj.options.shift()
      }
    },
    judgetAllSelected(node) {
      // 判断是否是全选,也就是看已选择的选中中包不包含"全选"
      let isAllSelected = false
      for(let i = 0; i < node.length; i++) {
          if(node[i][0] === '全选') {
            isAllSelected = true
            break;
          }
      }
      return isAllSelected
    },
    loopFlatData(list = [], parentNode = []) {
      list.length > 0 && list.forEach(e => {
        let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
        if(e.children && e.children.length > 0) {
          pNode.push(e.value)// 1 11
          this.loopFlatData(e.children, pNode)
        }else {
          if(e.label !== '全选') {
            if(parentNode.length > 0) {
              pNode.push(e.value)
              this.originData.push({ ...e, parentNode: pNode })
            }else {
              this.originData.push(e)
            }
          }
        }
      })
    },
    loopSelectData(list, parentNode = []) {
      list.length > 0 && list.forEach(e => {
        let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
        if(e.children && e.children.length > 0) {
          pNode.push(e.value)// 1 11
          this.loopSelectData(e.children, pNode)
        }else {
          if(parentNode.length > 0) {
            this.shopObj.value.push([...parentNode, e.value])
          }else {
            this.shopObj.value.push([e.value])
          }
        }
      })
    },
    checkIsAddAllSelected() {
      let list = this.shopObj.options; // 原始数据列表
      if(this.originData.length === 0) {
        this.loopFlatData(list) // 把所有的父子级平铺成一个一级列表
      }
      let origin = this.originData;
      let now = [...this.shopObj.value].filter(item => item[0] !== '全选')
      if(origin.length > now.length) {
        // 非全选时, 如果有之前选过全选,要把全选过滤掉
        this.shopObj.value = this.shopObj.value.filter(item => item[0] !== '全选')
      }else {
        // 当所有的数据都选择时, 要自动把全选勾选上
        if(this.shopObj.value[0] && this.shopObj.value[0][0] !== '全选') {
          this.shopObj.value = [['全选'], ...this.shopObj.value]
        }
      }
    },
    async selectHandle(node = []) {
      this.shopObj.value = []
      // 选中的数据格式: [['全选'], ['0'], ['1', '11', '111'], ['2', '21'],...]
      let list = this.shopObj.options
      let current = []; // 获取当前选中的option, 因为element文档中没有获取当前选中的option的方法,所以我通过上一次和本地的选中数据进行对比来获取
      if(node.length >= this.preSelected.length) {
        let keys = this.preSelected.map(item => JSON.stringify(item))
        current = node.filter(item => !keys.includes(JSON.stringify(item)))
        console.log('选中某项', current);
      }else {
        // 取消选中
        let keys = node.map(item => JSON.stringify(item))
        current = this.preSelected.filter(item => !keys.includes(JSON.stringify(item)))
        console.log('取消选中', current);
      }
      // 根据element的选中数据格式, 每一个选项都是一个列表, 列表第一项为父级value, 第二项为选中的子级value, ...以此类推
      const currentValue = current.length > 0 ? current[0][0] || '' : ''
      if(currentValue === '全选') {
        if(this.judgetAllSelected(node)) {
          this.loopSelectData(list)
        }else {
          this.shopObj.value = []
        }
      }else {
        this.shopObj.value = node
      }
      this.checkIsAddAllSelected(); // 主要是勾选或取消非“全选”项时，对于全选的逻辑处理
      this.preSelected = this.shopObj.value; // 保存上一次的选择结果
      this.changeHandle();
    },
    changeHandle() {
      // 这里是处理成自己需要的数据格式, 需要把全选的这一选项过滤掉
      // 原始选择的数据格式[['全选'], ['1', '11'], ['2', '21'],...]
      console.log('changeHandle: ', this.shopObj.value);
    },
    sure(){
      if(this.sendType==1&&this.shopObj.value.length==0){
        this.$message.warning('请选择店铺！')
        return
      }
      if(this.sendType==2&&this.formatObj.value.length==0){
        this.$message.warning('请选择业态！')
        return
      }
      if(this.floorObj==3&&this.formatObj.value.length==0){
        this.$message.warning('请选择楼层！')
        return
      }
      if(!this.title){
        this.$message.warning('请输入标题！')
        return
      }
      if(this.noticeType==0){
        if(this.contentLength==0){
          this.$message.warning('请输入内容!')
          return
        }
        if(this.contentLength>9999){
          this.$message.warning('内容长度不能超过9999')
          return
        }
      }
      if(this.noticeType==1){
        if(!this.noticeLink){
          this.$message.warning('请输入跳转链接！')
          return
        }
        if(!(/(http|https):\/\/[\w]+(.[\w]+)([\w\-\.,@?^=%&:/~\+#\u4e00-\u9fa5]*[\w\-\@?^=%&/~\+#])/.test(this.noticeLink))){
          this.$message.warning('跳转链接格式不正确！')
          return
        }
      }
      let params={
        type: this.sendType,
        title: this.title,
        showType: this.noticeType==0? 1:2
      }
      if(this.noticeType==0){
        params.content = this.content
        params.resource = this.images
        params.attachmentResource = this.files
      }
      if(this.noticeType==1){
        params.linkUrl = this.noticeLink
      }
      if(this.sendType==1){
        // 店铺
        let hasAll = this.shopObj.value.filter(temp=>{return temp[0]=='全选'})
        params.isAll = hasAll.length>0 ? true : false
        params.shopIds = (this.shopObj.value.filter(temp=>{return temp[0]!='全选'})).map(item=>{
          if(item.length==1){
            return item[0]
          }else{
            return item[1]
          }
        })
      }
      if(this.sendType==2){
        // 业态
        let hasAll = this.formatObj.value.filter(temp=>{return temp[0]=='全选'})
        params.isAll = hasAll.length>0 ? true : false
        params.businessIds = (this.formatObj.value.filter(temp=>{return temp[0]!='全选'})).map(item=>{
          if(item.length==1){
            return item[0]
          }else{
            return item[1]
          }
        })
      }
      if(this.sendType==3){
        // 楼层
        let hasAll = this.floorObj.value.filter(temp=>{return temp[0]=='全选'})
        params.isAll = hasAll.length>0 ? true : false
        params.floorIds = (this.floorObj.value.filter(temp=>{return temp[0]!='全选'})).map(item=>{
          if(item.length==1){
            return item[0]
          }else{
            return item[1]
          }
        })
      }
      this.$api.busapi.addNotice(params).then(res=>{
        if(res.code==200){
          this.noticeDialogVisible = false
          this.getItemList()
          this.$message.success('操作成功！')
        }else{
          this.$message.warning(res.msg)
        }
      })
    },
    back(){
      this.dialogClose()
    },
    addItem(){
      this.noticeDialogVisible = true
      setTimeout(()=>{
        // 设置富文本编辑器的title
        this.initTitle()
      },1000)
    },
    dialogClose(){
      this.noticeDialogVisible = false
    },
    goDetail(item){
      this.showType = 'detail'
      this.listId = item.id
    },
    onEditorBlur() {
      // 失去焦点触发事件
    }, 
    onEditorFocus() {
      // 获得焦点触发事件
    }, 
    onEditorChange(e) {
      let _this = this
      // 内容改变触发事件
      // console.log('content: ',this.content)
      // console.log('length: ',e.quill.getLength()-1)
      // e.quill.deleteText(800,4);
      // _this.TiLength=e.quill.getLength()-1
      this.contentLength = e.quill.getLength()-1
    },
    initTitle () {
      const titleConfig = [
  { Choice: '.ql-insertMetric', title: '跳转配置' },
  { Choice: '.ql-bold', title: '加粗' },
  { Choice: '.ql-italic', title: '斜体' },
  { Choice: '.ql-underline', title: '下划线' },
  { Choice: '.ql-header', title: '段落格式' },
  { Choice: '.ql-strike', title: '删除线' },
  { Choice: '.ql-blockquote', title: '块引用' },
  { Choice: '.ql-code', title: '插入代码' },
  { Choice: '.ql-code-block', title: '插入代码段' },
  { Choice: '.ql-font', title: '字体' },
  { Choice: '.ql-size', title: '字体大小' },
  { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
  { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
  { Choice: '.ql-direction', title: '文本方向' },
  { Choice: '.ql-header[value="1"]', title: 'h1' },
  { Choice: '.ql-header[value="2"]', title: 'h2' },
  { Choice: '.ql-align', title: '对齐方式' },
  { Choice: '.ql-color', title: '字体颜色' },
  { Choice: '.ql-background', title: '背景颜色' },
  { Choice: '.ql-image', title: '图像' },
  { Choice: '.ql-video', title: '视频' },
  { Choice: '.ql-link', title: '添加链接' },
  { Choice: '.ql-formula', title: '插入公式' },
  { Choice: '.ql-clean', title: '清除所选格式' },
  { Choice: '.ql-script[value="sub"]', title: '下标' },
  { Choice: '.ql-script[value="super"]', title: '上标' },
  { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
  { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
  { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
  { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
  { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
  { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
  { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
  { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
  { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
  { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
  { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
  { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
  { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
  { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
  { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' }
      ]
      // document.getElementsByClassName('ql-editor')[0].dataset.placeholder = ''
      for (let item of titleConfig) {
        let tip = document.querySelector('.quill-editor ' + item.Choice)
        if (!tip) continue
        tip.setAttribute('title', item.title)
      }
    },
    changeType(val){
      this.showType = val
    },
    removeFile(index){
      this.files.splice(index,1)
    },
    removeImg(index){
      this.images.splice(index,1)
    },
    showViedo(item){
      this.viedoUrl = item.url
    },
    showImg(item){
      this.imgDialogVisible = true
      this.imageUrl = item.url
      this.imgName = item.name
    },
    getDefaultImg(fileName){
        const splitIndex = fileName.lastIndexOf('.');
        const str = fileName.slice(splitIndex-1)
        var orign = 'https://reconova-test.oss-cn-zhangjiakou.aliyuncs.com/AIOS/miniprogram/pic/'
        if(str.indexOf('.pdf')!=-1){
          return orign + 'pdf1.png'
        }else if(str.indexOf('.doc')!=-1 || str.indexOf('.docx')!=-1){
          return orign + 'word.png'
        }else if(str.indexOf('.xls')!=-1 || str.indexOf('.xlsx')!=-1){
          return orign + 'excel.png'
        }else if(str.indexOf('.ppt')!=-1 || str.indexOf('.pptx')!=-1){
          return orign + 'file_ppt.png'
        }else if(str.indexOf('.zip')!=-1){
          return orign + 'zip.png'
        }else if(str.indexOf('.rar')!=-1){
          return orign + 'rar.png'
        }else{
          return orign + 'file-cion.png'
        }
        // if(['.zip','.rar','.pdf'].includes(type)){
        //   return require(`@/assets/${fileName.slice(splitIndex+1)}.png`)
        // }else{
        //   return require('@/assets/file_default.png')
        // }
      },
      closeViedo(){
        this.viedoUrl = ''
      },
      closeIframe(){
        this.fileUrl = ''
      },
    getFileImg(item){
      const fileType = item.url.substr(item.url.lastIndexOf(".")+1)
      if(['zip','rar'].includes(fileType)){
        this.$message.warning("暂不支持预览zip、rar类型的文件！")
      }
      if(['pdf'].includes(fileType)){
        this.fileUrl = item.url
      }
      if(['doc','docx','xls','xlsx','ppt','pptx'].includes(fileType)){
        this.fileUrl = "https://view.officeapps.live.com/op/view.aspx?src="+encodeURIComponent(item.url)
      }
      this.fileDialogVisible = true
      this.fileName = item.name
    },
    changeFile(file,fileList){
    
    },
//覆盖原有的上传逻辑
      async uploadFile(data,list) {
        const file = data.file;
        // 如果是cad和pdf文件不需要压缩，直接上传
        //上传给后端，拿到url
        let form = new FormData;
        const type = file.type.split('/')[1]
        let ie = isIE();
        if(['png', 'jpg','jpeg'].includes(type)&&!ie){
          const img = await uploadCommon.readImg(file);
          const blob = await uploadCommon.compressImg(img,500, 500,file.type);
          form.append("file", new window.File([blob], file.name, {type: file.type}));
        }else{
          form.append("file",file)
        }
        // form.append("biz",'notice');
        let loading = Loading.service(this.loadingOption);
        this.uploadFileNum++;
        uploadFile(form,'notice').then(res => {
          this.uploadFileNum--;
          if(this.uploadFileNum==0){
            loading.close();
          }
            this.images.push({
              title: file.name,
              name: file.name,
              url: res.data,
              size: file.size,
              // id: res.data.id,
              // isPicturePreview:res.data.isPicturePreview
            })
          this.$refs.upload.clearFiles()
        })
        console.log(this.images)
      },
      async uploadFile1(data) {
        const file = data.file;
        // 如果是cad和pdf文件不需要压缩，直接上传
        //上传给后端，拿到url
        let form = new FormData;
        const type = file.type.split('/')[1]
        let ie = isIE();
        if(['png', 'jpg','jpeg'].includes(type)&&!ie){
          const img = await uploadCommon.readImg(file);
          const blob = await uploadCommon.compressImg(img,500, 500,file.type);
          form.append("file", new window.File([blob], file.name, {type: file.type}));
        }else{
          form.append("file",file)
        }
        // form.append("biz", 'notice');
        let loading = Loading.service(this.loadingOption);
        this.uploadFileNum1++;
        uploadFile(form,'notice').then(res => {
          this.uploadFileNum1--;
          if(this.uploadFileNum1==0){
            loading.close();
          }
            this.files.push({
              name: file.name,
              url: res.data,
              size: file.size,
            })
          this.$refs.upload1.clearFiles()
        })
      },
      beforeUpload(file) {
        if(this.images.length<20){
          //验证文件格式和大小最大2M  50M
          if(file.type.indexOf('video')!=-1){
            file.size > 50 * 1024 * 1024&&this.$message.warning(`视频文件不能超过${50}M`)
            return !(file.size > 50 * 1024 * 1024)
          }
          if(file.type.indexOf('image')!=-1){
            file.size > 10 * 1024 * 1024&&this.$message.warning(`图片文件不能超过${10}M`)
            return !(file.size > 10 * 1024 * 1024)
          }
          // return uploadCommon.checkFileAndSize(file,2);
        }else{
          this.$message.error("最多上传20个图片");
          return false;
        }

      },
      overFile(files,fileList){
        if(files.length<=(20-this.images.length)){
          return true
        }else{
          this.$message.warning(`最多可上传20个图片,当前最多可选择 ${20-this.images.length} 个图片`);
          this.$refs.upload.clearFiles()
          return false
        }
      },
      beforeUpload1(file) {
        if(this.files.length<10){
          //验证文件格式和大小最大100M
          file.size > 100 * 1024 * 1024&&this.$message.warning(`文件不能超过${100}M`)
          return !(file.size > 100 * 1024 * 1024)
        }else{
          this.$message.error("最多上传10个附件");
          return false;
        }

      },
      overFile1(files,fileList){
        if(files.length<=(10-this.files.length)){
          return true
        }else{
          this.$message.warning(`最多可上传10个附件,当前最多可选择 ${10-this.files.length} 个附件`);
          this.$refs.upload1.clearFiles()
          return false
        }
      },
    /**
     * 搜索按钮点击
     **/
    searchEvent () {
      this.getItemList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.getItemList()
    },

    // 分页列表
    getItemList () {
      const params = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        title: this.searchData.params.title,
        type: this.searchData.params.type, 
        startTime: (this.searchData.params.date&&this.searchData.params.date[0]) || '',
        endTime: (this.searchData.params.date&&this.searchData.params.date[1]) || '',
        
      }
      this.$api.busapi.getNoticeList(params)
        .then((res) => {
          if (res.code === 200) {
            this.pager.count = res.data.total
            this.tableData = res.data.list.map(item => ({
              ...item,
              title:(item.title||'').length>10 ? (item.title || '').slice(0,10) + '...' : (item.title||''),
              type: item.type==1?'店铺':(item.type==2?'业态':(item.type==3?'楼层':''))
            }))
          }
        })
    }

  },
}
</script>
<style lang="scss" scoped>
.reportMarket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  .operation{
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-start;
    // margin-top: 10px;
    padding: 0 20px;
  }
  .el-table {
    flex: 1;
    margin-top: 30px;
    padding: 0 20px;
    overflow-y: auto;
  }
  .d-paging {
    margin-top: 50px;
    text-align: center;
  }
  &::v-deep .el-upload{
    display: block;
  }
  &::v-deep .el-dialog{
    // height: 88%;
    overflow-y: auto;
  }
  &::v-deep .el-dialog__body{
    max-height: 750px;
    overflow-y: auto;
  }
  .flex-row{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .conten{
    flex: 1;
    border: solid 1px #D9D9D9;
    min-height: 60px;
    max-height: 140px;
    overflow: auto;
    padding: 10px;
    // display: flex;
  }
  .title{
    width: 120px;
    text-align: right;
    flex-shrink: 0;
  }
  .conten-item{
    display: inline-block;
    color: #000;
    background: #F2F2F2;
    padding: 5px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
  }
}
::v-deep .imgDialog .el-dialog{
    height: 88%;
    overflow-y: auto;
  }
::v-deep .imgDialog .el-dialog__body{
    max-height: 100%;
    height: 750px;
    overflow-y: auto;
  }
.oticeDialogContent{
  &::v-deep .el-input__inner{
    width: 350px;
    height: 40px;
    font-size: 14px;
  }
  &::v-deep .el-input{
    width: 350px;
  }
  &::v-deep .ql-editor{
    min-height: 200px;
  }
  .upload-demo{
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin-top: 5px;
    margin-left: 10px;
    text-align: center;
    background: #F2F2F2;
    // border-radius: 10px;
  }
  .images-item{
    width: 70px;
    height: 70px;
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }
  .images-item img{
    width: 100%;
    height: 100%;
  }
  .wordfile{
    position: fixed;
    left: 0;
    right:0;
    top:0;
    bottom: 0;
    z-index:99;
    background-color: rgba(0, 0, 0, 0.2);
    iframe{
      margin-left: 10%;
      margin-top: 3%;
    }
  }
  .files-item{
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }
  .file-img{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .file-img img{
    width: 45px;
    height: 45px;
  }
  .file-img div{
    max-width: 73px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .delete{
    position: absolute;
    color: #fff;
    background: red;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    border-radius: 50%;
    right: -6px;
    top: -4px;
    cursor: pointer;
  }
}
.btn{
  text-align: right;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #409EFF;
  border-color: #409EFF;
}
::v-deep  .el-checkbox__input.is-checked + .el-checkbox__label{
  color: #409EFF;
}
::v-deep .el-checkbox__label{
  font-size: 14px;
}
</style>  
